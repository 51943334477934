import React, { useRef, useState, useEffect } from 'react';
import * as styles from '../styles/contact.module.css';
import Dropdown from './components/dropDown';
import { FaCheck } from 'react-icons/fa';
import Layout from './components/Layout';
import Dropdown2 from './components/dropDown2';
import { Link } from 'gatsby';

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from 'react-icons/fa';

const options2 = [
  {
    value: '25,000',
    label: '25,000',
  },
  {
    value: '50,000',
    label: '50,000',
  },
  {
    value: '200,000',
    label: '200,000',
  },
  {
    value: '300,000',
    label: '300,000',
  },
];

export default function Contact() {
  const [off, setOff] = useState(0);

  const [selectedOptions2, setSelectedOptions2] = useState(options2[0]);
  const [checked, setChecked] = useState(false);

  let contactBanner = useRef(null);

  const handleScroll = () => setOff(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [values, setValues] = useState({
    firstName: '',
    surname: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  });

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout>
      <div className={styles.contact_banner_wrapper}>
        <div>
          <div
            className={styles.contact_banner}
            ref={(el) => {
              contactBanner = el;
            }}>
            <h1 className={styles.contact_banner_bg_txt} data-text='CONNECT'>
              CONNECT
            </h1>
            <div
              className={styles.contact_banner_content}
              style={{ transform: `translateY(${off * -0.9}px)` }}>
              <h3 className={styles.contact_banner_content_header}>
                Get in Touch
              </h3>
              <p className={styles.contact_banner_content_text}>
                Whether you've got a product idea or you're looking to partner
                up, the LabLamb Works’ team is here to help; fill out our form
                below or give us a call at phone number
              </p>
            </div>
          </div>
        </div>
        <div className={styles.contact_section_wrapper}>
          <div>
            <div className={styles.contact_section_form}>
              <div className={styles.contact_section_form_inner}>
                <p>First Name</p>
                <input
                  type='text'
                  placeholder='First Name'
                  name='firstName'
                  value={values.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.contact_section_form_inner}>
                <p>Last Name</p>
                <input
                  type='text'
                  placeholder='Last Name'
                  name='surname'
                  value={values.surname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.contact_section_form2}>
              <div className={styles.contact_section_form_inner}>
                <p>Email Address</p>
                <input
                  type='text'
                  placeholder='Email Address'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.contact_section_form_inner}>
                <p>Phone Number</p>
                <input
                  type='text'
                  placeholder='Phone Number'
                  name='phone'
                  value={values.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.contact_section_company_input}>
              <p>Company Name</p>
              <input
                type='text'
                placeholder='Company Number'
                name='company'
                value={values.company}
                onChange={handleChange}
              />
            </div>
            <div className={styles.contact_section_dropdown}>
              <h6>Type of Project</h6>
              <Dropdown />
            </div>

            <div className={styles.contact_section_message_input}>
              <p>Leave A Message</p>
              <input
                type='text'
                placeholder='Let us know how we can help'
                name='message'
                value={values.message}
                onChange={handleChange}
              />
            </div>

            <div className={styles.contact_section_dropdown2}>
              <h6>What is your Budget?</h6>
              <Dropdown2 />
            </div>
            <div className={styles.contact_section_checkbox}>
              <input
                type='checkbox'
                id='check'
                onClick={() => setChecked(!checked)}
              />
              <label htmlFor='check'>
                I consent to LabLamb Works storing my data so they can respond
                to my inquiry
              </label>
              {checked && <FaCheck className={styles.tick} />}
            </div>

            <div className={styles.submit}>
              <button className={styles.submit_btn}>submit your message</button>
            </div>
          </div>
          <div className={styles.contact_section_info_wrapper}>
            <p>
              visit our <Link to='/Career/Career'>careers page</Link> for
              oppurtunities
            </p>

            <div className={styles.contact_section_info_icons}>
              <p>
                Read the latest news on our{' '}
                <Link to='/Blog/blogPost'>blog page</Link>{' '}
              </p>
              <div className={styles.social_icon}>
                <ul>
                  <li>
                    <Link to='/'>
                      <FaFacebook className={styles.fab} />
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      <FaTwitter className={styles.fab} />
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      <FaInstagram className={styles.fab} />
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      <FaYoutube className={styles.fab} />
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      <FaLinkedin className={styles.fab} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
